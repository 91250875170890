<template>
  <div>
    <div
      v-if="sent"
      class=""
    >
      <v-alert
        dense
        text
        type="success"
      >
        <p>
          {{ $t('L\'email de vérification a été envoyé. Veuillez vérifier votre nouvel email pour terminer le processus.') }}
        </p>
      </v-alert>
    </div>
    <v-form
      v-else
      ref="registerForm"
      v-model="valid"
      lazy-validation
      @submit.prevent="onSubmit"
    >
      <div class="">
        <p class="text-14 mb-1">
          {{ $t('E-mail actuel') }}
        </p>
        <v-text-field
          v-model="email"
          placeholder="example@mail.com"
          outlined
          :error-messages="errorMessages.email"
          :rules="emailRules"
          :label="$t('E-mail actuel')"
          dense
          class=""
        />
      </div>

      <div class="">
        <p class="text-14 mb-1">
          {{ $t("Mot de passe") }}
        </p>
        <v-text-field
          v-model="password"
          :type="isPasswordVisible ? 'text' : 'password'"
          placeholder="*********"
          outlined
          dense
          class=""
          :rules="passwordRules"
          :append-icon="isPasswordVisible ? 'mdi-eye-off-outline':'mdi-eye-outline'"
          @click:append="isPasswordVisible = !isPasswordVisible"
        />
      </div>
      <div class="mb-4">
        <p class="text-14 mb-1">
          {{ $t('Nouveau e-mail') }}
        </p>
        <v-text-field
          v-model="newemail"
          placeholder="example@mail.com"
          outlined
          :error-messages="errorMessages.newemail"
          :rules="emailNewRules"
          :label="$t('Nouveau e-mail')"
          dense
          class=""
        />
      </div>

      <div class="mb-4">
        <v-btn
          :disabled="!valid"
          block
          color="primary"
          class="text-capitalize font-600"
          type="submit"
          :loading="loading"
        >
          {{ $t("Enregistrez") }}
        </v-btn>
      </div>
    </v-form>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="snackBarColor"
    >
      {{ snackBarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn

          text
          v-bind="attrs"
          @click="isSnackbarBottomVisible = false"
        >
          {{ $t("Fermer") }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  import { i18n } from '@/plugins/i18n'

  export default {

    data () {
      return {
        sent: false,
        valid: false,
        loading: false,
        email: '',
        newemail: '',
        password: '',
        isPasswordVisible: false,
        errorMessages: { name: 'Est requis' },
        emailRules: [v => !!v || i18n.t('L\'e-mail est obligatoire')],
        emailNewRules: [v => !!v || i18n.t('Nouvel e-mail est requis')],
        passwordRules: [v => !!v || i18n.t('Le mot de passe est requis')],
        snackBarMessage: '',
        snackBarColor: null,
        isSnackbarBottomVisible: false,

      }
    },
    methods: {
      onSubmit () {
        const isFormValid = this.$refs.registerForm.validate()

        if (!isFormValid) return
        this.loading = true
        this.$nextTick(() => {
          this.loading = true
        })
        // eslint-disable-next-line no-undef
        axios({
          method: 'post',
          url: '/user/change_email',
          data: { email: this.email, newemail: this.newemail, password: this.password },
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        }).catch(error => {
          // this.loading = false
          this.loading = false
          console.log(error)
          // eslint-disable-next-line consistent-return
        }).then((response) => {
          if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
            if (response.data.errors) {
              // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              this.errorMessages = { ...response.data.errors }
            } else {
              this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)

              // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
            }

            return false
          }
          this.sent = true
        })
          .then(() => {
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            // TODO: Next Update - Show notification
            console.error('Oops, Unable to Register!')
            console.log('error :>> ', error.response || '')
            // this.errorMessages = error.response.data.error
          })
      },
      toast (message, color) {
        this.isSnackbarBottomVisible = true
        this.snackBarColor = color
        this.snackBarMessage = message
      },
    },
  }
</script>

<style lang="scss" scoped>
  .sign-up-card {
    width: 500px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 8px;
    margin: 2rem auto;
    box-shadow: rgb(3 0 71 / 9%) 0px 8px 45px;
    @media(max-width: 500px){
      width: 100%;
    }
    .sign-up-card-container {
      padding: 3rem 3.75rem 0px;
      @media(max-width: 500px){
        padding: 3rem 1rem 0px;
      }
    }
  }

</style>
