<template>
  <v-app>
    <div class="bg-body d-flex flex-column justify-center align-center min-vh-100">
      <div class="sign-up-card">
        <div class="sign-up-card-container">
          <div class="text-center mb-2">
            <h3 class="mb-0">
              <router-link to="/">
                <v-img
                  width="200"
                  src="@/assets/images/logo.png"
                  class="mx-auto"
                />
              </router-link>
            </h3>
          </div>
          <div
            v-if="changing"
            class="text-center mb-10"
          >
            <h3 class="mb-3">
              {{ $t('Changement d\'adresse e-mail') }}
            </h3>
            <p class="mb-2">
              <v-progress-circular indeterminate />
            </p>
          </div>
          <div
            v-else
            class=""
          >
            <change-email />
          </div>
        </div>
        <v-snackbar
          v-model="isSnackbarBottomVisible"
          :color="snackBarColor"
          :light="$vuetify.theme.dark"
          timeout="5000"
        >
          {{ snackBarMessage }}
        </v-snackbar>
      </div>
    </div>
  </v-app>
</template>

<script>
  import { i18n } from '@/plugins/i18n'
  import ChangeEmail from '@/views/profile/ChangeEmail'
  export default {
    components: {
      ChangeEmail,
    },
    data () {
      return {
        isSnackbarBottomVisible: false,
        snackBarMessage: '',
        snackBarColor: 'success',
        valid: false,
        checkbox: false,
        loading: false,
        changing: true,
        isPasswordVisible: false,
        isConfirmPasswordVisible: false,
        name: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        confirmPassword: '',
        errorMessages: { name: 'Est requis' },
        termsRules: [v => !!v || i18n.t('Accepter les conditions générales')],
        nameRules: [v => !!v || i18n.t('Le nom est requis')],
        firstNameRules: [v => !!v || i18n.t('Le prénom est requis')],
        lastNameRules: [v => !!v || i18n.t('Le nom de famille est requis')],
        emailRules: [v => !!v || i18n.t('L\'e-mail est obligatoire')],
        passwordRules: [v => !!v || i18n.t('Le mot de passe est requis')],
        confirmPasswordRules: [v => !!v || i18n.t('Le mot de passe de confirmation est requis')],
        token: null,

      }
    },
    created () {
      this.token = this.$router.currentRoute.query.token
      if (!this.token || this.token.trim().length < 3) {
        this.changing = false
      } else {
        this.onSubmit()
      }
    },
    methods: {
      onSubmit () {
        this.loading = true
        axios({
          method: 'post',
          url: '/user/reset-email',
          data: {
            token: this.$router.currentRoute.query.token,
          },
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        }).catch(error => {
          // this.loading = false
          this.loading = false
          console.log(error)
          // eslint-disable-next-line consistent-return
        })
          .then((response) => {
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              // ? Set user's data in localStorage for UI/Other purpose
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                this.errorMessages = { ...response.data.errors }
              } else {
                this.isSnackbarBottomVisible = true
                this.snackBarMessage = response.data.message
                this.snackBarColor = this.$vuetify.theme.currentTheme.error
              }
              this.changing = false
              return false
            }

            this.isSnackbarBottomVisible = true
            this.snackBarColor = this.$vuetify.theme.currentTheme.success
            this.snackBarMessage = this.$t('Le mot de passe a été réinitialisé. Connectez-vous pour continuer')
            this.$router.push('/profile')
          })
          .then(() => {
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            // TODO: Next Update - Show notification
            console.error('Oops, Unable to Reset!')
            console.log('error :>> ', error.response || '')
            // this.errorMessages = error.response.data.error
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
.sign-up-card {
  width: 500px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;
  margin: 2rem auto;
  box-shadow: rgb(3 0 71 / 9%) 0px 8px 45px;
  @media(max-width: 500px){
    width: 100%;
  }
  .sign-up-card-container {
    padding: 3rem 3.75rem 0px;
    @media(max-width: 500px){
      padding: 3rem 1rem 0px;
    }
  }
}

</style>
